// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';

.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: 0 auto;
  max-width: 900px;
}

.fc.fc-ltr.fc-unthemed {
  padding: 35px;
}

.fc-license-message {
  display: none;
  visibility: hidden;
  max-width: 1px;
  max-height: 1px;
  background-color: transparent;
  color: transparent;
}

.nav-tabs {
  justify-content: center;
  .nav-link {
    border-color: rgba(240, 240, 240, 0.5);
    color: #444 !important;
    &:hover, &:focus, &.active {
      color: #000 !important;
    }
  }
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.booking-carousel {
  .carousel-item {
    background-color: #000;
    > img {
      width: auto !important;
      max-width: 100%;
      max-height: 75vh;
      margin: auto
    }
  }

  .carousel-caption {
    > h3 {
      color: #fff;
      font-size: large;
      text-shadow: #000 0 0 12px;
    }
  }
}
