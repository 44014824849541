.loginPage {
  background-image: url('./assets/img/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.card-profile-image2 {
  position: relative; 
  text-align: center;
}
.card-profile-image2 img {
  max-width: 180px;
  border-radius: 0.375rem;
  margin:0 auto 20px;
}

.datePickerDiv .react-datepicker-manager,
.datePickerDiv .react-datepicker-wrapper,
.datePickerDiv .react-datepicker__input-container {
  width: 100%;
} 

.submenu > li > a {
  padding-left: 2rem !important;
}

.nav-link{
  color: #fff !important;
}

.sidebar-main {
  background: #2c6a82 !important;
}

.sidebarIcon {
  color: #2c6a82 !important;
  text-align: center;
  background: #ffffff !important;
  padding: 2px;
  border-radius: 5px;
  vertical-align: middle;
  top: 0  !important;
  margin-right: 10px;
}

.slots_input{
  padding: 5px;
  padding-left: 10px !important;
}


/* CSS For Washer Assigning UL Start */
ul.stepper {
  counter-reset: section;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1em -1.5rem;
  padding: 1.5rem;
}

.stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: center;
  -ms-flex-pack: justify;
  justify-content: center;
  align-items: center;
}

ul.stepper li a {
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.primary-color,
ul.stepper li.active a .circle,
ul.stepper li.completed a .circle {
  background-color: #5e72e4 !important;
}

ul.stepper li a .circle {
  display: inline-block;
  color: #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, .38);
  width: 1.75rem;
  height: 1.75rem;
  text-align: center;
  line-height: 1.7rem;
  margin-right: .5rem;
}

ul.stepper li.active a .label,
ul.stepper li.completed a .label {
  font-weight: 600;
  color: rgba(0, 0, 0, .87);
}

ul.stepper li a .label {
  display: inline-block;
  color: rgba(0, 0, 0, .38);
}

.stepper-horizontal li {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
}

.stepper-horizontal li:hover {
  background-color: rgba(0, 0, 0, .06);
}

.line {
  min-height: 2px;
  /* margin: auto; */
  background-color: rgba(0, 0, 0, .12);
  width: 50px !important;
}


@media (max-width: 47.9375rem) {
  ul.stepper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }

  .line {
      min-height: 30px;
      width: 1px !important;
      margin: 0px 0 0px 30px;
  }

  .stepper-horizontal li {
      margin: 10px 0
  }

  ul.stepper li a {
      padding: 15px 20px;
  }
}
/* CSS For Washer Assigning UL End */

.hasSibling {
  background: #DCBEBE  !important;
}

.hasParent {
  background: #d4cccc;
}

.datepicker-form-control {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s 
}

.datepicker-input-group {
  flex-wrap: nowrap !important;
}

.input-shadow{
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  border: none !important;
}

.border-none {
  border: none !important;
}
