// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';

.fc.fc-ltr.fc-unthemed {
  padding: 35px;
}

.fc-license-message {
  display: none;
  visibility: hidden;
  max-width: 1px;
  max-height: 1px;
  background-color: transparent;
  color: transparent;
}

.fc-timeline-event .fc-title {
  font-size: 12px;
  padding: 1px;
}

.tooltip {
  opacity: 1;
}
